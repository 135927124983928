<template>
    <div class="home">
        <b-img src="images/bild.jpg" fluid />
        <span v-html="$t('presentation.team')" />
        <b-card-group deck>
            <b-card title="Hubertus Weigandt"
                style="max-width:400px;"
                img-top>
                <p class="card-text">
                    Lead Procurement Manager Packaging<br/><br/>
                    <a href="mailto: hubertus.weigandt@habafamilygroup.com">hubertus.weigandt@habafamilygroup.com</a><br/>
                    Tel. +49 (0)9564 929-2151
                </p>
            </b-card>
            <b-card title="Steffen Müller"
                style="max-width:400px;"
                img-top>
                <p class="card-text">
                    Director Produktbereitstellung<br/><br/>
                    <a href="mailto: steffen.mueller@habafamilygroup.com"> steffen.mueller@habafamilygroup.com</a><br/>
                    Tel. +49 (0)9564 929-2203
                </p>
            </b-card>
            <b-card title="Niclas Heiderhoff"
                style="max-width:400px;"          
                img-top>
                <p class="card-text">
                    Einkaufskoordinator Materialeinkauf und Dienstleistungen<br/><br/>
                    <a href="mailto: niclas.heiderhoff@ebnerstolz.de">niclas.heiderhoff@ebnerstolz.de</a><br/>
                    Tel. +49 (0)152 2460 3895
                </p>
            </b-card>
        </b-card-group>
        <br/>
        <br/>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';

    export default {
        name: 'team',
        data() {
            return {};
        },
        computed: {
            ...mapGetters({
                auth: 'auth/auth'
            })
        }
    }
</script>